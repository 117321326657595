import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Button from '../Button'
import Card from '../Card'
import Hero from '../Hero'
import Modal from '../Modal'
import Section from '../Section'
import SectionHeading from '../SectionHeading'
import RoadmapSlider from '../Slider/RoadmapSlider'
import Spacing from '../Spacing'
import Team from '../Team'

export default function Home() {
  pageTitle('Home');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const accordionData1 = [
    {
      question: 'How to Mint an NFT?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    },
    {
      question: 'What happens once purchase NFT?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    },
    {
      question: 'How can I obtain NFTs?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    },
    {
      question: 'Do NFTs appreciate in value?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    }
  ]
  const accordionData2 = [
    {
      question: 'How can I obtain NFTs?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    },
    {
      question: 'How can I obtain NFTs?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    },
    {
      question: 'Do NFTs appreciate in value?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    },
    {
      question: 'How to Mint an NFT?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    },
    {
      question: 'How can I obtain NFTs?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges.'
    }
  ]
  return (
    <>
      <Hero 
        title=' The Ultimate<br /> Golf NFT.'
        mintNumber='0'
        mintMax='3000'
        mintPrice='35'
        mintDeathLine='22 July' 
        bgUrl='/images/hero_bg0.png'
        heroImageUrl='/images/Logo.Png' 
        animatedUrl='/images/hero_img_sm.png' 
        overlay='0.3'
        bgOpacity='0.4'
      />
      <Section tag='section' id='about'>
        <Spacing lg='100' md='70'/>
        <Section className="container">
          <Section className="row align-content-center">
            <Section className="col-lg-6">
              <Section className='cs-right_space_40'>
                <SectionHeading 
                  title='About Us'
                  subtitle='Our Story'
                />
                <Spacing lg='20' md='20'/>
                <Section tag='p'>Matic Mini Golf, developed by Cameron Bright, is a unique combination of mini golf and blockchain technology. Players enjoy a fun-filled mini golf game and earn digital currency called $BALLS as they achieve victories. The blockchain integration ensures secure ownership and enables players to trade or sell their earned balls on various blockchain marketplaces, adding an extra dimension of excitement and value to the game.</Section>
                <Section tag='p'>In Matic Mini Golf, users can purchase custom ball skins, trails, and clubs using the digital currency $BALLS they earn during gameplay. The game also offers exclusive collaboration items with projects like "DeGolfers," allowing players to showcase their support and enjoy rare in-game content. These cosmetic items add a personalized touch to the mini golfer avatar, fostering a vibrant and engaged gaming community.</Section>
                <Spacing lg='25' md='15'/>
                <Section className="cs-btn_group">
                  {/* <Modal modalType='mint' btnText='Mint Now' /> */}
                  <Button 
                    variant='cs-btn cs-color1'
                    btnLink='https://discord.gg/jFK7trukCm' 
                    btnText='Play Beta'
                  />
                </Section>
              </Section>
              <Spacing lg='0' md='40'/>
            </Section>
            <Section className="col-lg-6">
              <img src="/images/NFT.png" alt="About" />
            </Section>
          </Section>
        </Section>
        <Spacing lg='100' md='70'/>
      </Section>
      <Spacing lg='65' md='35'/>
      <Section className="container">
        <SectionHeading 
          title='How To Mint'
          subtitle='In 4 Simple Steps'
        />
        <Spacing lg='50' md='30'/>
        <Section className="row">
          <Section className="col-xl-3 col-md-6">
            <Card 
              title='Connect Wallet'
              emoji='👋'
            />
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-xl-3 col-md-6">
            <Card 
              title='Select Your Quantity'
              emoji='👉'
            />
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-xl-3 col-md-6">
            <Card 
              title='Confirm the Transaction'
              emoji='👍'
            />
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-xl-3 col-md-6">
            <Card 
              title='Recive Your NFTs'
              emoji='🙂'
            />
            <Spacing lg='25' md='25'/>
          </Section>
        </Section>
      </Section>
      <Section id='team'>
        <Spacing lg='70' md='40'/>
        <Section className="container">
          <SectionHeading 
            title='Meet The Team'
            subtitle='Team'
          />
          <Spacing lg='50' md='30'/>
          <Section className="row">
            <Section className="col-lg-3 col-sm-6">
              <Team 
                src='./images/cam.jpg'
                name='Cameron Bright' 
                designation='Founder' 
                facebookHref='https://twitter.com/cameronbright' 
              />
              <Spacing lg='25' md='25'/>
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team 
                src='./images/fehu.jpg'
                name='Rekt Fehu' 
                designation='Founder' 
                facebookHref='https://twitter.com/RektFehu' 
              />
              <Spacing lg='25' md='25'/>
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team 
                src='./images/pio.jpg'
                name='0xPioneers' 
                designation='Smart Contract Development and Advisors' 
                facebookHref='https://twitter.com/0xPioneers' 
              />
              <Spacing lg='25' md='25'/>
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team 
                src='./images/box.jpg'
                name='Boxtropolis' 
                designation='Game Development' 
                facebookHref='https://twitter.com/Boxtropolis_NFT' 
              />
              <Spacing lg='25' md='25'/>
            </Section>
          </Section>
        </Section>
      </Section>
      <Section tag='section' id='roadmap'>
        <Spacing lg='70' md='40'/>
        <Section className="container">
          <SectionHeading 
            title='Roadmap'
            subtitle='Our Goals'
          />
          <Spacing lg='75' md='30'/>
          <Section className="cs-accent_seperator_1 cs-accent_color"></Section>
          <RoadmapSlider/>
        </Section>
      </Section>
      <Spacing lg='100' md='70'/>
    </>
  )
}
